import { CognitoAccessToken, CognitoUser } from 'amazon-cognito-identity-js';
import { ICardDetailsPayload } from '../auth';
import { AuthenticationType } from '../utils/Browser';

export interface IAuthService {
    signIn(email: string, password: string): Promise<CognitoUser>;
    signInWithCode(code: string, clientId: string, redirectUri: string): Promise<CognitoUser>;
    signInWithTempPassword(email: string, tempPassword: string, newPassword: string): Promise<CognitoUser>;

    initiateAuth(restrictedToken: CognitoUser, type: AuthenticationType): Promise<IAuthResult>;
    resolveAuthChallenge(challenge: IMultiFactorAuthChallenge, answer?: string): Promise<IAuthResult>;

    signUp(email: string, onboardingFlow?: string, opportunityId?: string): Promise<string>;
    verifySignUp(userId: string, confirmationCode: string): Promise<{ email: string; tempPassword: string; }>;
    confirmSignUp(userId: string, confirmationCode: string): Promise<void>;
    resendVerificationCode(email: string, onboardingFlow?: string): Promise<void>;
    findIdentityProvider(email: string): Promise<IFindIdentityProviderResult>;

    resetPassword(email: string): Promise<void>;
    resetPasswordConfirm(email: string, newPassword: string, verificationCode: string): Promise<void>;

    changePassword(oldPassword: string, newPassword: string, session: IUserSession): Promise<void>;
    verifySecurityCode(code: string, session: IUserSession, isTempCode?: boolean): Promise<ISecurityCodeResponse>;
    setSecurityCode(newCode: string, oldCode: string | null, session: IUserSession, isTempCode?: boolean): Promise<void>;
    hasSecurityCode(session: IUserSession): Promise<boolean>;
    getCardDetails(cdeToken: string, code: string, session: IUserSession): Promise<ICardDetailsPayload>;
    checkPassword(password: string, session: IUserSession): Promise<boolean>;
    resetSecurityCode(session: IUserSession): Promise<void>;

    initPhoneVerification(restrictedUser: CognitoUser): Promise<void>;
}

export type IAuthResult = ISuccessAuthResult | IChallengeAuthResult;
export interface IChallengeAuthResult {
    session?: never;
    challenge: IMultiFactorAuthChallenge;
}
export interface ISuccessAuthResult {
    session: IUserSession;
    challenge?: never;
}

export interface IVerifyService {
    getUserAuthenticationInfo(restrictedToken: CognitoAccessToken): Promise<IUserAuthenticationInfo>;
    resolveSmsChallenge(restrictedToken: CognitoAccessToken, challengeId: string, code: string): Promise<SmsChallengeStatus>;
    getPushAuthChallengeStatus(restrictedToken: CognitoAccessToken, challengeId: string): Promise<PushChallengeStatus>;
}

export interface IMessageService {
    contactSupport(): void;
    userSignedIn(session: IUserSession): void;
    userSignInFailed(errorMessage: string): void;
    getUserSession(): Promise<IUserSession | undefined>;
    cardDetailsOpened(): void;
    securityCodeChangeCanceled(): void;
    securityCodeChangedSuccessfully(): void;
    securityCodeDisabled(): void;
    resetSecurityCode(): void;
    changeHeight(height?: number): void;
    noSecurityCodeModal(): void;
    passwordModal(): void;
    enterCodeModal(): void;
}

export interface IServiceContext {
    messageService: IMessageService;
    authService: IAuthService;
    verifyService: IVerifyService;
    userService: IUserService;
}

export type IFindIdentityProviderResult = {
    hasExternalProvider: true;
    name: string;
} | {
    hasExternalProvider: false;
};

export interface ISecurityCodeResponse {
    statusCode?: number;
    body?: string;
    isValid?: boolean;
}

export interface IHasSecurityCodeResponse {
    statusCode?: number;
    body?: string;
    hasCode: boolean;
}

export interface ISetSecurityCodeResponse {
    statusCode?: number;
}

export enum MessageCodes {
    SIGNED_IN = 'signed-in',
    SESSION_REQUEST = 'session-request',
    SESSION_RESPONSE = 'session-response',
    CONTACT_SUPPORT = 'contact-support',
    RESET_SECURITY_CODE_SEND = 'reset-security-code-send',
    CHANGE_SECURITY_CODE_SUCCESS = 'security-code-change-success',
    CHANGE_SECURITY_CODE_CANCELED = 'security-code-change-canceled',
    CHANGE_HEIGHT = 'change-height',
    SHORT_MODAL = 'short-modal',
    TALL_MODAL = 'tall-modal',
    TALL_MODAL_WITH_BUTTON = 'tall-modal-with-button',
    CHANGE_TITLE_SECURITY_CODE_DISABLED = 'change-title-security-code-disabled',
    ERROR = 'error',
}

export type ChallengeType = 'PAYHAWK_SMS' | 'PAYHAWK_RESTRICTED_TOKEN' | 'PAYHAWK_PUSH' | 'PAYHAWK_USER_PROFILE_SETUP' | 'PAYHAWK_PUSH_SETUP';

export interface ICognitoChallenge {
    type: ChallengeType;
    challengeId: string;
    [key: string]: string;
}

export interface ISmsCognitoChallenge extends ICognitoChallenge {
    challengeId: string;
    phone: string;
}

export interface IPushCognitoChallenge extends ICognitoChallenge {
    challengeId: string;
    createdAt: string;
    expiresAt: string;
}

export interface IMultiFactorAuthChallenge {
    user: CognitoUser;
    data: ICognitoChallenge;
}

export type SmsChallengeStatus = 'pending' | 'approved' | 'canceled';
export type PushChallengeStatus = 'pending' | 'expired' | 'approved' | 'denied';

type CognitoClientId = string;

export interface IUserAuthenticationInfo {
    mfaRequired: boolean;
    mfaEnabled: boolean;
    locale: string;
    configuredFactors: AllowedAuthFactors;
    allowedFactors: AllowedAuthFactors;
    clientIds: CognitoClientIdMap;
}

export enum AuthenticationFactor {
    SMS = 'sms',
    PushAuthentication = 'pushAuthentication',
    Credentials = 'credentials'
}

export type SecondAuthFactor = Exclude<AuthenticationFactor, 'credentials'>;

export type AllowedAuthFactors = { [key in SecondAuthFactor]: boolean };

export type ConfiguredFactors = {
    [factor in SecondAuthFactor]: boolean;
};

export type CognitoClientIdMap = {
    restricted: string;
    portal: FactorClientMap;
    mobile: FactorClientMap;
};

export type FactorClientMap = {
    [factor in AuthenticationFactor]: CognitoClientId;
};

export interface IUserInfo {
    sub: string; // subject = user id
    email: string;
    givenName?: string;
    familyName?: string;
    phoneNumber?: string;
    phoneNumberVerified?: boolean;
}

export interface IEditUserInfo {
    givenName?: string;
    familyName?: string;
    phoneNumber?: string;
}

export interface IUserService {
    getUserData(restrictedAccessToken: CognitoAccessToken): Promise<IUserInfo | undefined>;
    updateUserData(restrictedAccessToken: CognitoAccessToken, id: string, userData: Partial<IEditUserInfo>): Promise<void>;
    verifyPhone(restrictedAccessToken: CognitoAccessToken, code: string): Promise<void>;
}
