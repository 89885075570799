import { CognitoAccessToken } from 'amazon-cognito-identity-js';
import { IMultiFactorAuthState } from './multi-factor-auth';

export interface IResetPasswordError {
    verificationCodeError?: string;
    newPasswordError?: string;
}

export interface ICardDetailsCommon {
    cvv: number;
    expirationDate: string;
    scheme: string;
}

export interface ICardDetailsPayload extends ICardDetailsCommon {
    number: number;
}

export interface ICardDetails extends ICardDetailsCommon {
    cardNumber: number;
}

export interface IAuthState {
    multiFactorAuth: IMultiFactorAuthState;

    email: string;
    userId?: string;
    tempPassword?: string;

    isSigningIn: boolean;
    signInError?: string;

    isSigningInWithExternalProvider: boolean;
    externalSignInError?: AuthErrorType;

    isCheckingEmail: boolean;
    checkEmailError?: string;

    isResettingPassword: boolean;
    resetPasswordError?: IResetPasswordError;

    isLoginWithTempPassword?: boolean;

    isSigningUp: boolean;
    signUpError?: string;
    registrationError?: string;
    verifyCodeError?: string;
    isGettingSecurityCode?: boolean;
    hasSecurityCode?: boolean;
    isVerifyingSecurityCode: boolean;
    setNewCodeError?: string;
    isSendingNewSecurityCode: boolean;
    isCodeValid?: boolean;
    isResendingVerificationCode: boolean;

    isSendingResetSecurityCode: boolean;
    resetSecurityCodeError?: string;
    isCodeReset: boolean;

    isGettingCardDetails: boolean;
    cardDetailsError?: string;
    cardDetails?: ICardDetails;
    hasExceededMaximumRetries?: boolean;
    remainingRetryCount?: number;

    isShowPasswordCheck: boolean;
    passwordCheckLoading: boolean;
    passwordCheckSuccess?: boolean;
    securityCode: string;
}

export interface IAuthActions {
    checkPassword(password: string): Promise<boolean>;
    showPasswordCheck(): Promise<void>;
    setEmail(email: string): void;
    signIn(email: string, password: string): Promise<void>;
    externalProviderSignIn(provider: string): void;
    processAuthenticationParams(baseSearch: string, search: string): Promise<void>;
    checkEmail(email: string): Promise<void>;

    contactSupport(): void;

    cleanSignUp(): void;
    signUp(email: string, onboardingFlow?: string, opportunityId?: string): Promise<void>;
    resendVerificationCode(email: string, onboardingFlow?: string): Promise<void>;
    verifySignUp(userId: string, verificationCode: string): Promise<void>;
    verifyInvitation(userId: string, verificationCode: string, email?: string): Promise<void>;
    confirmSignUp(email: string, userId: string, tempPassword: string, newPassword: string, newPasswordConfirm: string, verificationCode: string, isLoginWithTempPassword?: boolean): Promise<void>;

    resetPassword(email: string): Promise<void>;
    resetPasswordConfirm(email: string, newPassword: string, verificationCode: string): Promise<void>;

    hasSecurityCode(): Promise<void>;
    verifySecurityCode(code: string, isTemp: boolean): Promise<void>;
    getCardDetails(token: string, code: string): Promise<void>;
    cardDetailsOpened(): void;
    setSecurityCode(newCode: string, oldCode: string | null, isTempCode?: boolean): Promise<void>;
    cancelSecurityCodeChange(): void;
    securityCodeChangedSuccessfully(): void;
    changeHeight(height?: number): void;
    changeTitleToSecurityCodeDisabled(): Promise<void>;

    resetSecurityCode(): void;
}

export interface IAuthStateAction {
    type: AuthActionType;
    data?: any;
}

export enum AuthActionType {
    'SET_USERNAME',
    'SIGN_IN',
    'SIGN_IN_SUCCESS',
    'SIGN_IN_FAIL',
    'SIGN_UP',
    'SIGN_UP_SUCCESS',
    'SIGN_UP_FAIL',
    'VERIFY_SIGN_UP',
    'VERIFY_SIGN_UP_SUCCESS',
    'VERIFY_SIGN_UP_FAIL',
    'VERIFY_INVITATION',
    'VERIFY_INVITATION_SUCCESS',
    'VERIFY_INVITATION_FAIL',
    'CONFIRM_SIGN_UP',
    'CONFIRM_SIGN_UP_SUCCESS',
    'CONFIRM_SIGN_UP_FAIL',
    'RESEND_CONFIRMATION_CODE',
    'RESEND_CONFIRMATION_CODE_SUCCESS',
    'RESEND_CONFIRMATION_CODE_FAIL',
    'CLEAN_SIGN_UP',
    'CLEAN_SIGN_IN',
    'RESET_PASSWORD',
    'RESET_PASSWORD_SUCCESS',
    'RESET_PASSWORD_FAIL',
    'CHECK_EMAIL',
    'CHECK_EMAIL_SUCCESS',
    'CHECK_EMAIL_FAIL',
    'GET_SECURITY_CODE',
    'GET_SECURITY_CODE_SUCCESS',
    'GET_SECURITY_CODE_FAIL',
    'VERIFY_SECURITY_CODE',
    'VERIFY_SECURITY_CODE_SUCCESS',
    'VERIFY_SECURITY_CODE_FAIL',
    'SET_SECURITY_CODE',
    'SET_SECURITY_CODE_SUCCESS',
    'SET_SECURITY_CODE_FAIL',
    'GET_CARD_DETAILS',
    'GET_CARD_DETAILS_FAIL',
    'GET_CARD_DETAILS_SUCCESS',
    'GET_CARD_DETAILS_FAIL_REMAINING_RETRY_COUNT',
    'GET_CARD_DETAILS_FAIL_MAXIMUM_RETRIES_EXCEEDED',
    'RESET_SECURITY_CODE',
    'RESET_SECURITY_CODE_SUCCESS',
    'RESET_SECURITY_CODE_FAIL',
    'SHOW_PASSWORD_CHECK',
    'PASSWORD_CHECK_LOADING',
    'PASSWORD_CHECK_SUCCESS',
    'PASSWORD_CHECK_FAIL',
    'EXTERNAL_SIGN_IN',
    'EXTERNAL_SIGN_IN_SUCCESS',
    'EXTERNAL_SIGN_IN_FAIL',
    'RESTRICTED_SESSION_CREATED',
    'RESTRICTED_SESSION_UPDATE',
    'FULL_SESSION_CREATED',
    'MFA_CHALLENGE_INIT',
    'MFA_CHALLENGE_INIT_SUCCESS',
    'MFA_CHALLENGE_INIT_FAIL',
    'MFA_CHALLENGE_RESOLVE',
    'MFA_CHALLENGE_RESOLVE_SUCCESS',
    'MFA_CHALLENGE_RESOLVE_FAIL',
    'MFA_CHALLENGE_EXPIRED',
}

export enum CognitoErrorCode {
    CodeMismatchException = 'CodeMismatchException',
    ExpiredCodeException = 'ExpiredCodeException',
    InvalidParameterException = 'InvalidParameterException',
    InvalidPasswordException = 'InvalidPasswordException',
    LimitExceededException = 'LimitExceededException',
    NewPasswordRequiredException = 'NewPasswordRequiredException',
    NotAuthorizedException = 'NotAuthorizedException',
    PasswordResetRequiredException = 'PasswordResetRequiredException',
    TooManyRequestsException = 'TooManyRequestsException',
    UserNotConfirmedException = 'UserNotConfirmedException',
    UserNotFoundException = 'UserNotFoundException',
    UsernameExistsException = 'UsernameExistsException',
}

export enum RegistrationErrorCode {
    TempPasswordExpired = 'TempPasswordExpired',
    NetworkError = 'NetworkError',
    Unknown = 'Unknown',
}

export interface IAuthParams {
    code?: string;
    id_token?: string;
    access_token?: string;
    error_description?: string;

    /**
     * @deprecated
     * Use the error_description to obtain an error from the parent page
     */
    errorCode?: string;

    /**
     * @deprecated
     * Use the error_description to obtain an error from the parent page
     */
    auth_error?: string;
}

export enum AuthErrorType {
    RegistrationErrorNoSuchUser = 'RegistrationErrorNoSuchUser'
}